
import { defineComponent } from "vue";
import cardLeft from "@/components/card-left.vue";
import cardRight from "@/components/card-right.vue";

export default defineComponent({
  name: "Home",
  components: {
    cardLeft,
    cardRight,
  },
});
