
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    titleInmp: {
      type: String,
    },
    detailsInmp: {
      type: String,
    },
  },
  name: "cardLeft",
});
