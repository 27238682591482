<template>
  <div class="home">
    <cardLeft
      titleInmp="What is miisandmemes?"
      detailsInmp="fhdajihfkdajhgkhgafafhdklj"
    />
    <cardRight
      titleInmp="What is miisandmemes?"
      detailsInmp="fhdajihfkdajhgkhgafafhdklj"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import cardLeft from "@/components/card-left.vue";
import cardRight from "@/components/card-right.vue";

export default defineComponent({
  name: "Home",
  components: {
    cardLeft,
    cardRight,
  },
});
</script>
