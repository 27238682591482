
import { defineComponent } from "vue";
import bg from "@/components/bg.vue";

export default defineComponent({
  name: "Home",
  components: {
    bg,
  },
});
