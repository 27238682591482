<template>
  <div class="card facing-left">
    <div class="in-card-c">
      <h2>{{ titleInmp }}</h2>
      <p>{{ detailsInmp }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    titleInmp: {
      type: String,
    },
    detailsInmp: {
      type: String,
    },
  },
  name: "cardLeft",
});
</script>
<style>
.card {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.8);
  background: rgb(255, 255, 255);
  display: flex;
}
.in-card-c {
  padding: 2px 16px;
}
.facing-left {
  -webkit-transform: rotate(-0.3deg);
  -moz-transform: rotate(-0.3deg);
  -o-transform: rotate(-0.3deg);
  transform: rotate(-0.3deg);
  margin-bottom: 1em;
}
</style>
