<template>
  <bg />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
<script lang="ts">
import { defineComponent } from "vue";
import bg from "@/components/bg.vue";

export default defineComponent({
  name: "Home",
  components: {
    bg,
  },
});
</script>
