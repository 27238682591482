<template>
  <div
    class="wd-bg"
    :style="{
      backgroundImage: 'url(' + require('@/assets/bg.jpg') + ')',
    }"
  >
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "bg",
});
</script>

<style>
.wd-bg {
  width: auto;
  height: 49.6vw;
}
@media screen and (max-width: 820px) {
  .wd-bg {
    width: auto;
    height: 105vw;
  }
}
@media screen and (max-width: 480px) {
  .wd-bg {
    width: auto;
    height: 181vw;
  }
}
@media screen and (max-width: 375px) {
  .wd-bg {
    width: auto;
    height: 212vw;
  }
}
@media screen and (max-width: 1280px) {
  .wd-bg {
    width: auto;
    height: 69vw;
  }
}
@media screen and (max-width: 1024px) {
  .wd-bg {
    width: auto;
    height: 131.7vw;
  }
}
</style>
